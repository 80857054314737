/* Mixins */
.corp-detail-drawer-component .ant-tabs-tabpane {
  max-height: 78vh;
  overflow: auto;
}
.corp-detail-drawer-component .location-tree {
  margin: 10px;
}
.corp-detail-drawer-component .ant-descriptions-item-label {
  width: 200px;
}
.corp-detail-drawer-component .save-button {
  margin-left: 16px;
}
.corp-detail-drawer-component .ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 16px;
  margin-bottom: 8px;
}
.corp-detail-drawer-component .menu-item-wrapper {
  border: solid 1px #d9d9d9;
}
.corp-detail-drawer-component .menu-item-wrapper .menu-item-header {
  padding: 12px 16px;
  background-color: #fafafa;
  font-size: 16px;
  font-weight: bold;
}